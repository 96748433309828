@import "vars";
@import "mixins";

.input-field {
  @include font-base(16px, 400, 20px);

  width: 100%;
  height: 44px;
  padding-block: 12px;
  padding-inline: 16px 40px;
  border: 1.5px solid $neutral-border;
  border-radius: 8px;
  cursor: pointer;
  color: $neutral-primary;
  outline: none;

  &::placeholder {
    @include font-base(16px, 400, 20px);

    color: $neutral-secondary;
  }
  &:focus {
    border-color: $blue;
  }
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  & svg {
    width: 12px;
    height: 12px;
  }
}
