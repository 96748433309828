.info-item {
  margin-bottom: 12px;
  display: inline-flex;
  flex-flow: row nowrap;
  width: 100%;

  &__subtitle {
    @extend .basic-text;

    margin-right: 3px;
    white-space: nowrap;
    color: $neutral-secondary;
  }

  &__title {
    @extend .basic-text;

    flex: 1;
    overflow-wrap: anywhere;
    white-space: pre-line;
    word-break: break-all;
    color: $neutral-primary;
  }

  &__sub-list {
    @extend .basic-text;

    list-style: none;
    color: $neutral-primary;
    margin-top: 1px;
    padding-left: 10px;

    & > li:not(:last-of-type) {
      padding-bottom: 6px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
