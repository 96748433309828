@import "vars";
@import "mixins";
@import "extends";

slpt-select-field {
  & .slpt-form-field {

    & .slpt-select {
      height: 100%;
    }

    & slpt-select-input {
      margin-top: 12px;

      & .slpt-text-input-container {
        & input {
          @extend .input-field;
        }

        &__icon {
          @extend .input-icon;
        }
      }

      & .slpt-select__trigger--open {
        & .slpt-text-input-container__icon--right {
          transform: translateY(-50%) rotateZ(180deg) !important;
        }
      }
    }
  }
}

.slpt-dropdown__menu {
  border: none !important;
  border-radius: 2px;
  box-shadow: 0 3px 15px rgba(0, 116, 217, .16);

  & .slpt-select__menu {
    padding: 8px 0 !important;

    slpt-select-input-item {
      padding-left: 5px;
    }

    &-item {
      height: 36px !important;
      padding: 0 !important;

      & .option-item {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 4px 16px;
        display: flex;
        align-items: center;

        &__text {
          @include font-base(16px, 400, 20px);

          color: $neutral-primary;
        }

        &::before {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          display: none;
          background: $brand;
          content: '';
        }
      }

      &--highlighted {
        & .option-item::before {
          display: block;
        }
      }
    }
  }
}
