@import "vars";
@import "mixins";
@import "extends";

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.loading-mask {
  z-index: 9999 !important;
}

.modal-content {
  border: none;
}

.alert {
  z-index: 99999;
}

.field {
  width: 100%;

  &-wrapper {
    position: relative;
  }

  &-error {
    @include font-base(16px, 400, 20px);

    margin-top: 8px;
    color: $marker-red;
  }
}

.expired {
  color: $warning-color !important;

  &::after {
    content: '';
    display: inline-block;
    mask: url("/assets/icons/warning.svg") no-repeat 50% 50%;
    width: 14px;
    height: 14px;
    margin-bottom: -2px;
    margin-left: 2px;
    background-position: center;
    background-color: $warning-color;
  }
}

.alert__message {
  white-space: pre-wrap;
}
