@import "vars";
@import "mixins";

.modal-overlay {
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  z-index: $z-modal-overlay !important;
  background: rgba($neutral-background, .6);

  .basic-text {
    @include font-base(18px, 400, 24px);
  }

  .modal-wrapper {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $z-modal-overlay + 1;
    width: 50vw;
    max-width: 1000px;
    min-width: 300px;
    max-height: 1000px;
    min-height: 200px;
    box-shadow: 0 13px 20px rgba(77, 114, 155, .15);
    transform: translate(-50%, -50%);
    background: $white-color;

    @media (max-width: $tablet-max-width) {
          width: 75vw;
      }

    @media (max-width: $mobile-max-width) {
          width: 95vw;
          max-height: 95vh;
    }
  }

  .modal-header {
    padding: 24px 40px 16px;
    border-bottom: 1px solid $neutral-border;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      @include font-base(21px, 700, 32px);

      color: $neutral-primary;
    }

    &__sub-title {
      @include font-base(14px, 500, 14px);

      color: $neutral-secondary;
    }

    &__close {
      display: flex;
      cursor: pointer;
    }

    @media (max-width: $mobile-max-width) {
      padding: 10px 15px;

      &__title {
        @include font-base(18px, 700, 24px);

        color: $neutral-primary;
      }

      &__sub-title {
        @include font-base(12px, 500, 12px);

        color: $neutral-secondary;
      }
    }
  }

  .modal-body {
    flex: 1;
    padding: 24px 40px 40px;
    overflow-y: auto;

    &.small {
      padding: 24px 40px 0;
    }

    @media (max-width: $mobile-max-width) {
      padding: 5px 15px;
      &, &.small {
        padding: 10px 15px;
      }
    }
  }


  .button.back {
    margin: 32px 40px 40px;

    @media (max-width: $mobile-max-width) {
      margin: 10px 15px;
    }

    prtl-button {
      & .btn, button {
        width: 100%;
      }
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > .button, prtl-button, slpt-button {
    margin-top: 0;
    margin-right: 16px;

    &:last-child{
      margin-right: 0;
    }
  }
}
