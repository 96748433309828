@import "vars";
@import "mixins";
@import "extends";

slpt-date-field, prtl-date-field {
  & .slpt-form-field {
    & .slpt-form-input-container {
      position: relative;
      margin-top: 12px;

      & input {
        @extend .input-field;
      }

      & .slpt-text-input-container__icon {
        @extend .input-icon;

        cursor: pointer;
      }
    }
  }

  &.error {
    & .slpt-form-field {
      & .slpt-form-input-container {
        & input {
          border-color: $marker-red;
        }
      }
    }
  }

  &-error {
    @include font-base(16px, 400, 20px);

    margin-top: 8px;
    color: $marker-red;
  }
}

.slpt-dropdown__menu {
  border: none !important;
  border-radius: 2px;
  box-shadow: 0 3px 15px rgba(0, 116, 217, .16);

  & .slpt-datepicker__calendar, ngb-datepicker {
    border: none !important;
    box-shadow: none !important;

    & .ngb-dp-arrow-btn {
      cursor: pointer;
    }
  }

  .slpt-datepicker__menu {
    border: none !important;
  }
}


.slpt-datepicker-day__date {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  color: #212b36;
  font-size: 12px;
}

.ngb-dp-month-name {
  font-size: larger;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
}
