@import "vars";
@import "mixins";

.page-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  & .page-header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      @include font-base(24px, 700, 32px);

      color: $neutral-primary;
    }

    &__actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;

      & > * {
        height: 36px;
        margin-left: 16px;
      }

      .slpt-btn {
        min-width: 0;
        width: 36px;
        padding: 0;
      }
    }
  }

  @media (max-width: $tablet-max-width) {
    padding: 24px;
  }

  @media (max-width: $mobile-max-width) {
    padding: 0;

    .page-header {
      margin: 6px 12px;

      &__title {
        @include font-base(18px, 700, 24px);
      }

      &__actions {
        & > * {
          margin-left: 6px;
        }

        .slpt-btn {
          transform: scale(0.75);
        }
      }
    }
  }
}
