/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,600;1,700;1,900&display=swap');

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
/* Importing Armada Theme*/
@import "@sailpoint-priv/armada-angular/src/sailpoint/theme/styles/theme";
/* Import SCSS Variables */

@import "reset";
@import "vars";
/* Import Components */
@import "common";
@import "page-container";
@import "modal";
@import "info-item";
@import "date-picker";
@import "select";
@import "checkbox";
@import "text-field";

@import "./scss/fontawesome.scss";
@import "./scss/solid.scss";