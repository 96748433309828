@import "vars";
@import "mixins";
@import "extends";

.slpt-form-label {
  @include font-base(16px, 600, 20px);

  color: $neutral-primary;
}

.slpt-form-help-text {
  @include font-base(16px, 400, 20px);

  margin-left: 4px;
  color: $neutral-secondary;
}


slpt-text-field {
  & .slpt-form-field {

    & .slpt-text-input-container {
      position: relative;
      margin-top: 12px;

      & input {
        @extend .input-field;
      }
    }
  }

  &.error {
    & .slpt-form-field {
      & .slpt-text-input-container {
        & input {
          @extend .input-field;

          border-color: $marker-red;
        }
      }
    }
  }
}

.clear-icon {
  position: absolute;
  right: 20px;
  bottom: 15px;
  width: 14px;
  height: 14px;
  display: flex;
  cursor: pointer;
}
