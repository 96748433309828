@import 'vars';

@mixin font-base(
    $size: $font-size-base,
    $font-weight: 400,
    $height: $line-height-base,
    $font-style: normal,
    $font-family: $font-family-base
 ) {
    font: $font-weight $font-style #{$size}/#{$height $font-family};
}
