@import "vars";

.slpt-checkbox-container {
  &--checked {
    .slpt-checkbox-container-style {
      background: $blue;
      border-color: $blue;
    }
  }

  &:hover {
    .slpt-checkbox-container-style {
      border-color: $blue;
    }
  }
}

.slpt-dropdown__menu {
  .checkbox-list {
    &-item {
      padding: 0;
      cursor: pointer;
      white-space: nowrap;
      height: 36px !important;

      .slpt-checkbox-container {
        width: 100%;
        height: 36px !important;
        margin-bottom: 0;
        cursor: pointer;
        font-weight: 400;
        display: flex;
        align-items: center;

        .slpt-form-checkbox-icon {
          display: inline-block;
          position: absolute;
          bottom: 2px;
          left: 2px;
        }

        &-style {
          cursor: pointer;
          position: relative;
          min-width: 16px;
          height: 16px;
        }
      }
    }
  }
}


