$font-family-base: 'Source Sans Pro', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.4;


$white-color: #FFFFFF;
$blue: #0074D9;
$blue-dark: #011E69;
$brand: #005BC0;
$neutral-primary: #212B36;
$neutral-secondary: #637381;
$neutral-border: #E6E9F0;
$neutral-background: #F3F6F8;
$light-gray: #E5E5E5;
$marker-red: #DD2C00;
$warning-color: #CC6600;

$sidebar-width: 258px;
$header-height: 80px;
$mobile-header-height: 45px;

$tablet-max-width: 1200px;
$mobile-max-width: 768px;

$z-modal-overlay: 900;
